import React, { useState } from 'react';
import { graphql } from 'gatsby';
import {
  PageLayout,
  PageHeading,
  EnquireFormModal,
  NDISCondition,
} from 'components';
import ReactMarkdown from 'react-markdown';
import { SlideUpPageContent } from 'components/animations';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { NDISDetailsPagePropType } from 'types';

const NDISDetails = ({ data, pageContext }: NDISDetailsPagePropType) => {
  const {
    title,
    name,
    tagline,
    Banner,
    description,
    secondDescription,
    secondImage,
    image,
    EnquireButtonText,
    ContactFormModalTitle,
    Seo,
  } = data.allStrapiNdsiConditions.edges[0].node;

  const { strapiServicesPage, allStrapiNdsiCategories, NdisCondition } = data;
  const Image =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;
  const secondDescImage =
    secondImage && secondImage.localFile
      ? getImage(secondImage.localFile.childImageSharp?.gatsbyImageData)
      : null;
  const [isEnquireModalOpen, setisEnquireModalOpen] = useState(false);

  const handleEnquireModalOpen = () => {
    setisEnquireModalOpen(true);
  };
  return (
    <PageLayout
      title={title}
      metaTitle={Seo?.metaTitle ?? name}
      description={Seo?.metaDescription}
      image={Seo?.shareImage?.localFile?.publicURL}
      bannerTitle={Banner?.title}
      bannerTagline={Banner?.tagline}
      bannerImage={Banner?.image}
      bannerButton={Banner?.ctaButton}
      bannerPlaceholder={Banner?.postcodePlaceholder}
      showNdisFields
    >
      <div className="container">
        <PageHeading title={title} subtitle={tagline} level={1} />
        <SlideUpPageContent>
          <div className="service-page-description">
            {description && (
              <div className="service-page-description-content">
                <ReactMarkdown className="md-html">{description}</ReactMarkdown>
              </div>
            )}
            {Image && (
              <div className="service-page-image">
                <GatsbyImage
                  image={Image}
                  alt={(image?.alternativeText && title) || ''}
                />
              </div>
            )}
          </div>
          <div className="service-page-description">
            {secondDescription && (
              <div className="service-page-description-content">
                <ReactMarkdown className="md-html">
                  {secondDescription}
                </ReactMarkdown>
              </div>
            )}
            {secondDescImage && (
              <div className="service-page-image">
                <GatsbyImage
                  image={secondDescImage}
                  alt={(secondImage?.alternativeText && title) || ''}
                />
              </div>
            )}
          </div>
        </SlideUpPageContent>
        {EnquireButtonText && (
          <div className="service-page-cta-link-container">
            <button
              className="service-page-cta-link"
              type="button"
              onClick={handleEnquireModalOpen}
            >
              <span>{EnquireButtonText}</span>
            </button>
          </div>
        )}
        <NDISCondition
          data={strapiServicesPage.NDISCondition}
          list={NdisCondition}
          categories={allStrapiNdsiCategories}
          selectedCategory={pageContext.category}
          parentSlug={strapiServicesPage.ndisPageSlug}
        />
        <EnquireFormModal
          isEnquireModalOpen={isEnquireModalOpen}
          setisEnquireModalOpen={setisEnquireModalOpen}
          contactFormModalTitle={ContactFormModalTitle}
          modalText=""
          optionalMessage
          dealTitle={title}
          showNdisFields
        />
      </div>
    </PageLayout>
  );
};

export default NDISDetails;

export const query = graphql`
  query NDISConditionQuery($id: String) {
    allStrapiNdsiConditions(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          tagline
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          secondDescription
          secondImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          Seo {
            id
            metaTitle
            metaDescription
            shareImage {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(width: 400, height: 400)
                }
              }
            }
          }
          Banner {
            title
            tagline
            ctaButton
            postcodePlaceholder
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ContactFormModalTitle
          EnquireButtonText
          name
        }
      }
    }
    strapiServicesPage {
      ndisPageSlug
      NDISCondition {
        content
        title
      }
    }
    allStrapiNdsiCategories {
      edges {
        node {
          id
          title
        }
      }
    }
    NdisCondition: allStrapiNdsiConditions {
      edges {
        node {
          id
          name
          slug
          ndis_category {
            id
            title
          }
        }
      }
    }
  }
`;
